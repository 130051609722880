import React from "react";
import "./home.css";

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://instagram.com/das.istsr?igshid=YmMyMTA2M2Y="
        className="home__social-icon"
        target="_blank"
      >
        <i class="uil uil-instagram"></i>
      </a>

      <a
        href="https://www.facebook.com/dasistsachin/"
        className="home__social-icon"
        target="_blank"
      >
        <i class="uil uil-facebook-f"></i>
      </a>

      <a
        href="https://www.linkedin.com/in/sachin-kumar-20116424b"
        className="home__social-icon"
        target="_blank"
      >
        <i class="uil uil-linkedin"></i>
      </a>
    </div>
  );
};

export default Social;
