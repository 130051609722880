import Work1 from "../../assets/work1.png";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";
import Work6 from "../../assets/work6.jpg";

export const projectsData = [
  {
    id: 1,
    link: "https://project-duplex.netlify.app",
    image: Work1,
    title: "Vista Homes",
    category: "america",
  },
  {
    id: 2,
    link: "https://chrome.google.com/webstore?hl=en",
    image: Work2,
    title: "Parsons Mill",
    category: "india",
  },
  {
    id: 3,
    link: "https://project-as12-109.netlify.app/",
    image: Work3,
    title: "Ulefoss Norway",
    category: "other",
  },
  {
    id: 4,
    link: "https://chrome.google.com/webstore?hl=en",
    image: Work4,
    title: "Congleton Farms",
    category: "india",
  },
  {
    id: 5,
    image: Work5,
    link: "https://chrome.google.com/webstore?hl=en",
    title: "Amber Ridge",
    category: "america",
  },

  {
    id: 6,
    image: Work6,
    link: "https://chrome.google.com/webstore?hl=en",
    title: "Brooke Hill",
    category: "america",
  },
];

export const projectsNav = [
  {
    name: "all",
  },

  {
    name: "america",
  },

  {
    name: "india",
  },

  {
    name: "other",
  },
];
